import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
    Accueil: "/",
    // Blog: "/blog",
    "Mention Légale": "/fr/mention-légale"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/legal-notice"
);

  const LegalNotice = props => {
    return (
      <Layout location={props.location} alternateLangs={alternateLangs}>
  
        <React.Fragment>
          <SEO
            title="Mentions Légales"
            description="Mentions légales concernant les services de Matthias Kupperschmidt dans le secteur du marketing en ligne, y compris l'optimisation des moteurs de recherche, les implémentations d'analyses web, et le conseil en marketing en ligne"
            lang="fr"
            alternateLangs={alternateLangs}
          />
          <MainContent
            article={false}
  
          >
            <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
  
            <H as="h1">Mention Légale</H>

<p>Matthias Kupperschmidt propose divers services (appelés ci-après "services") dans le secteur du marketing en ligne, y compris l'optimisation des moteurs de recherche, les implémentations d'analyses web, la consultation sur des sujets de marketing en ligne ainsi que des formations et ateliers.</p>

<p>L'utilisation des services fournis est permise uniquement pour le but prévu décrit dans ces Conditions Générales et/ou la commande. En outre, toute utilisation ou traitement à d'autres fins n'est permis que sous les conditions strictes des lois danoises sur la protection des données, en particulier la loi sur la protection des données et le Règlement Général sur la Protection des Données (RGPD) de l'UE. Toute utilisation ou exploitation impropre n'est pas permise. En particulier, l'utilisation complète des services et contenus fournis à des fins commerciales de quelque nature que ce soit et sous toute forme de média (imprimé, électronique, sur CD, etc.) ainsi que la lecture des répertoires, bases de données, etc., à des fins précitées ou à d'autres fins commerciales n'est pas permise et sera poursuivie par le fournisseur selon la loi applicable en épuisant tous les recours légaux disponibles.</p>
<p>La disponibilité des services individuels ainsi que l'exactitude et la complétude des contenus/entrées/données ne sont pas garanties.</p>
<p>Ces Conditions Générales régissent les relations entre Matthias Kupperschmidt et ses clients et partenaires contractuels (appelés ci-après "partenaire contractuel").</p>

<p>Section 1 Portée</p>
<p>Les services de Matthias Kupperschmidt sont exécutés uniquement sur la base de ces Conditions Générales. Par conséquent, elles s'appliquent également à toutes les futures relations d'affaires même si elles ne sont pas expressément convenues à nouveau.</p>
<p>L'utilisation des services offerts par Matthias Kupperschmidt au partenaire contractuel est régie exclusivement par ces Conditions Générales (CG) ainsi que par les conditions particulières des produits/services respectifs. Pour les offres, services, projets individuels, promotions et autres services, notamment les services de projet au-delà de cela, les conditions à convenir dans des accords séparés s'appliquent.</p>
<p>Les conditions générales des partenaires contractuels ou d'autres tiers ne sont pas applicables même si Matthias Kupperschmidt ne s'y oppose pas expressément et/ou rend ses services sans objection.</p>

<p>Section 2 Portée générale des services</p>
<p>Entre autres, Matthias Kupperschmidt est actif dans les domaines de l'optimisation des moteurs de recherche, des analyses web, du marketing entrant, de la consultation sur des sujets de marketing en ligne ainsi que des formations et ateliers. La description détaillée du service commandé dans chaque cas peut être vue dans la commande respective.</p>
<p>Les services et contenus offerts par Matthias Kupperschmidt sont fournis et publiés par Matthias Kupperschmidt sauf indication contraire spécifique. Tout contenu rendu accessible par des tiers est une information fournie par le créateur, l'auteur ou le distributeur respectif et non une information fournie par Matthias Kupperschmidt. Matthias Kupperschmidt n'est donc pas responsable de l'exactitude ou de la fiabilité de ce contenu.</p>
<p>Les services offerts par Matthias Kupperschmidt ne sont qu'une invitation à soumettre une proposition pour conclure un contrat. Le partenaire contractuel est lié à sa déclaration pendant dix jours ouvrables. Une confirmation par écrit (un e-mail suffit) de Matthias Kupperschmidt est requise pour la conclusion d'un contrat. Un contrat d'affaires est alors conclu sur la base des CG applicables.</p>
<p>Matthias Kupperschmidt n'est pas tenu de conclure des contrats. Il est en droit de refuser la conclusion de contrats sans donner de raisons. Les modifications ultérieures et les ajouts aux contrats nécessitent la confirmation écrite de Matthias Kupperschmidt. Des accords séparés doivent être conclus par les partenaires contractuels pour des services supplémentaires.</p>
<p>Matthias Kupperschmidt se réserve également le droit d'exclure ultérieurement les partenaires contractuels de l'utilisation des services s'il existe des preuves que le partenaire contractuel abuse des services ou les utilise d'une manière susceptible de mettre en danger la réputation de Matthias Kupperschmidt. Cela s'applique en particulier si le partenaire contractuel viole des obligations découlant de la relation contractuelle ou nuit à d'autres partenaires contractuels de Matthias Kupperschmidt.</p>
<p>Dans la mesure où un partenaire contractuel utilise le logiciel de l'autre partenaire contractuel respectif dans le cadre de l'utilisation des services, l'autre partenaire contractuel respectif lui accorde – sauf disposition contraire – un droit d'utilisation non exclusif et non transférable, pour son usage propre, limité dans le temps à la durée du contrat et, en termes de contenu, limité à l'utilisation dans le cadre de la fourniture du service. D'autres droits d'utilisation ne sont pas accordés au partenaire contractuel.</p>
<p>À des fins de maintenance et pour effectuer des réparations, Matthias Kupperschmidt est en droit d'interrompre la fourniture des services ou de parties de ceux-ci dans la mesure où cela est raisonnable pour le partenaire contractuel. En général, Matthias Kupperschmidt s'efforcera d'effectuer les travaux de maintenance et de réparation à des moments où l'impact en sera le moins lourd possible. Cela ne donne lieu à aucune demande de recours ou de compensation de la part du partenaire contractuel sauf si Matthias Kupperschmidt est en faute.</p>
<p>Matthias Kupperschmidt est en droit de conclure des relations contractuelles similaires avec des concurrents du partenaire contractuel. Cependant, Matthias Kupperschmidt s'efforcera – sans y être obligé – de protéger les intérêts légitimes du partenaire contractuel à cet égard et d'éviter les conflits d'intérêts. Matthias Kupperschmidt est en droit de fournir des services partiels ainsi que des services par des tiers.</p>
<p>Les délais et dates demandés par le partenaire contractuel ne sont contraignants que lorsque Matthias Kupperschmidt les a expressément confirmés par écrit et si le partenaire contractuel a créé en temps voulu toutes les conditions nécessaires à la fourniture du service qui relèvent de sa sphère d'influence.</p>
<p>Dans la mesure et aussi longtemps que le partenaire contractuel n'a pas rempli ses obligations de coopération nécessaires à la fourniture du service, même après une demande écrite de Matthias Kupperschmidt et la fixation d'un délai de grâce raisonnable, Matthias Kupperschmidt sera libéré de l'obligation affectée par cette omission à la date convenue dans le contrat de projet. Le délai convenu dans la commande/contrat pour la fourniture sera prolongé en conséquence.</p>

<p>Section 3 Conclusion du contrat, étendue du contrat</p>
<p>Chaque service doit être commandé séparément par le partenaire contractuel (commande individuelle/accord de niveau de service). Les parties contractantes se fourniront mutuellement le contenu nécessaire à l'exécution de la commande. Si des codes de suivi sont intégrés dans ce contenu, ils ne peuvent être modifiés ou supprimés par l'autre partenaire contractuel respectif qu'après consultation et accord préalable.</p>
<p>Matthias Kupperschmidt établira une facture pour le partenaire contractuel pour chaque promotion réalisée, indiquant clairement toutes les informations spécifiées dans l'Article 1.</p>

<p>Section 4 Obligations du partenaire contractuel</p>
<p>Le client s'engage à soutenir les mesures prises par Matthias Kupperschmidt. Le client fournira à Matthias Kupperschmidt toutes les informations et données nécessaires à l'exécution de la commande.</p>
<p>Le partenaire contractuel s'engage à s'assurer que le contenu qu'il fournit et associé à l'utilisation des services de Matthias Kupperschmidt ne viole pas la législation applicable de l'État danois; cela s'applique en particulier aux contenus illégaux, immoraux ou pornographiques ou à ceux qui glorifient la guerre ou qui sont susceptibles de poser un risque moral pour les enfants et les jeunes ou de nuire à leur bien-être.</p>
<p>Le partenaire contractuel doit également signaler tous les événements et circonstances qui pourraient être importants pour l'exécution correcte et en temps opportun du contrat. Cela s'applique également aux événements et circonstances dont le partenaire contractuel prend connaissance pendant la durée du contrat. Les retards de projet dus au fait que le partenaire contractuel ne respecte pas les obligations de coopération requises ou ne les respecte pas en temps voulu sont à la charge du partenaire contractuel et n'affectent pas le droit à rémunération de Matthias Kupperschmidt.</p>
<p>Toute violation de l'obligation spécifiée dans l'Article 1 donne à Matthias Kupperschmidt le droit de résilier immédiatement le contrat entre Matthias Kupperschmidt et le partenaire contractuel. Matthias Kupperschmidt se réserve le droit de résiliation immédiate même s'il y a seulement un soupçon que le partenaire contractuel diffuse ou tolère du contenu tel que décrit dans l'Article 1. Matthias Kupperschmidt est en droit mais non obligé de surveiller la légalité du contenu.</p>
<p>Le partenaire contractuel s'engage à respecter strictement les spécifications techniques et de contenu exigées par Matthias Kupperschmidt. Tout retard et/ou changement concernant les services fournis par Matthias Kupperschmidt résultant du non-respect de ces spécifications sont à la charge du partenaire contractuel et ne donnent lieu à aucune réclamation contre Matthias Kupperschmidt.</p>
<p>En outre, le partenaire contractuel est responsable de s'assurer que toutes les informations et documents nécessaires à l'exécution de ses obligations contractuelles et à la mise en œuvre correcte de la collaboration sont mis à disposition de Matthias Kupperschmidt en temps voulu, complètement et sous une forme adaptée à l'exécution avant la mise en œuvre convenue du contrat.</p>
<p>Si une partie est empêchée de fournir le service contractuel et/ou le contenu en raison de circonstances dont elle n'est pas responsable, cette partie doit en informer l'autre partie en conséquence et transmettre sans délai un contenu et/ou des services adaptés aux circonstances. Si les circonstances précitées durent plus de 24 heures, les parties doivent se mettre d'accord sur la manière de procéder.</p>
<p>Le partenaire contractuel n'est pas autorisé à utiliser, reproduire, diffuser, publier, modifier ou exploiter de quelque manière que ce soit les marques et/ou logos de Matthias Kupperschmidt, de ses clients et partenaires, sauf si Matthias Kupperschmidt y a expressément consenti par écrit au préalable. En outre, le partenaire contractuel est tenu de respecter les droits d'auteur ou de marque de tiers, tant allemands qu'étrangers.</p>
<p>Le partenaire contractuel accepte la transmission de son contenu, de ses données et d'autres informations à des tiers dans la mesure où cela est nécessaire et requis pour l'exécution de la relation contractuelle entre les parties.</p>

<p>Section 5 Durée du contrat/résiliation</p>
<p>Les estimations de coûts de Matthias Kupperschmidt ne sont pas contraignantes. Matthias Kupperschmidt s'engage à informer immédiatement le partenaire contractuel de toute variation des coûts réels par rapport à l'estimation si la variation est supérieure à dix pour cent.</p>
<p>Le contrat conclu entre Matthias Kupperschmidt et le partenaire contractuel prend fin à l'expiration de la durée convenue. Pour le reste, la durée du contrat peut être vue dans l'offre.</p>
<p>Si une durée minimale a été convenue avec le partenaire contractuel, l'accord est automatiquement prolongé de la durée convenue à moins que l'extension ne soit contestée au plus tard 30 jours avant l'expiration de la durée de contrat en question.</p>
<p>Les contrats conclus pour une durée indéterminée peuvent être résiliés par les deux parties par écrit à tout moment avec un préavis de trois mois à la fin du mois.</p>
<p>Le droit de résilier le contrat pour un motif valable reste inchangé. Un motif valable est notamment donné en cas de violation par l'une des parties de ses obligations et devoirs essentiels découlant du contrat ou de la législation applicable de l'État danois et si cette partie ne rétablit pas l'état contractuel malgré un avertissement écrit de l'autre partie dans les 7 jours suivant la réception de l'avertissement. L'avertissement doit spécifier la violation d'une obligation ou d'un devoir et indiquer les possibilités de résiliation pour motif valable. Les obligations contractuelles essentielles sont notamment les obligations de confidentialité.</p>

<p>Pour Matthias Kupperschmidt, un motif valable est donné si</p>
<p>l'ouverture de procédures d'insolvabilité ou de faillite contre les actifs du partenaire contractuel a été déposée</p>
<ol>
  <li>tout autre manquement coupable aux obligations contractuelles essentielles ou aux devoirs d'un partenaire contractuel s'ils n'ont pas été immédiatement éliminés malgré un avertissement.</li>
</ol>
<p>Jusqu'à ce que la résiliation prenne effet, chaque partenaire contractuel conserve son droit à tous les frais et commissions qui seront encore encourus. Jusqu'à ce moment-là, les partenaires contractuels sont également tenus de respecter leurs obligations contractuelles. Toutes les obligations qui vont au-delà de la durée du contrat, en particulier celles relatives à la protection des données et à la confidentialité, restent inchangées par cela.</p>
<p>Toutes les résiliations doivent être faites par écrit ou par e-mail.</p>

<p>Section 6 Rémunération et échéances</p>
<p>La rémunération convenue est due à la fin de la consultation et/ou de la mise en œuvre des mesures. Une acceptation n'est pas requise.</p>
<p>Matthias Kupperschmidt a droit à un paiement adéquat déjà avant la fin de la consultation et de la mise en œuvre des mesures. En cas de doute, l'adéquation est basée sur le temps de travail déjà fourni. En cas de consultation permanente, Matthias Kupperschmidt est en droit de facturer les services immédiatement après leur fourniture. La rémunération est basée sur l'offre faite par Matthias Kupperschmidt.</p>

<p>Section 7 Transfert d'argent, conditions de paiement</p>
<p>La rémunération, ou la répartition des ventes, est toujours effectuée selon le découpage spécifié dans la commande individuelle.</p>
<p>Sauf accord contraire dans la commande individuelle, le règlement est effectué par Matthias Kupperschmidt sur une base mensuelle. Tous les chiffres de vente et les frais sont des sommes nettes ; la TVA est indiquée séparément.</p>
<p>Matthias Kupperschmidt est en droit de demander des paiements anticipés jusqu'à 100% du volume de la commande ; les paiements anticipés sont dus à la signature du contrat.</p>
<p>En cas de retard de paiement, Matthias Kupperschmidt est en droit de facturer des intérêts de retard au partenaire contractuel – tout en se réservant le droit de faire valoir d'autres intérêts de dommages – à hauteur de cinq points de pourcentage au-dessus du taux d'intérêt de base respectif. Les intérêts de retard sont plus bas si le partenaire contractuel prouve un dommage inférieur.</p>
<p>Le partenaire contractuel a un droit de rétention ou un droit de compensation uniquement dans la mesure où les créances du partenaire contractuel sont incontestées et ont été légalement établies.</p>

<p>Section 8 Droits de propriété et protection du droit d'auteur, droits d'utilisation</p>
<p>Les droits d'auteur et autres droits de propriété sur les services fournis par Matthias Kupperschmidt restent la propriété de Matthias Kupperschmidt.</p>
<p>Matthias Kupperschmidt accorde au partenaire contractuel des droits d'utilisation non exclusifs, temporaires et territorialement illimités pour l'exploitation du service complet livré conformément au contrat (résultats du travail).</p>
<p>Le partenaire contractuel n'est pas autorisé, cependant, à vendre le contenu et les informations livrés à des tiers ou à les mettre à disposition de tiers en dehors de leur but prévu.</p>
<p>Les modifications des services fournis par Matthias Kupperschmidt à réaliser par le partenaire contractuel ou un tiers mandaté par lui ne sont autorisées qu'avec le consentement exprès de Matthias Kupperschmidt.</p>
<p>L'exercice des droits d'utilisation n'est autorisé que si le partenaire contractuel n'est pas en retard de paiement à ce moment-là.</p>
<p>Sur la base d'accords avec des employés, agents et tout titulaire de droits tiers, Matthias Kupperschmidt s'assure que le partenaire contractuel peut exercer librement les droits d'utilisation contractuels.</p>

<p>Section 9 Garantie et responsabilité</p>
<p>Matthias Kupperschmidt exploitera ses services dans le cadre de ses possibilités techniques et n'assume aucune garantie ou assurance pour une disponibilité ininterrompue et/ou constamment sans erreur de ses services ; en particulier, pas pour les pannes de ligne et/ou les défaillances de connexion, les erreurs matérielles et/ou logicielles ni pour les actions de tiers (par exemple, les virus ou les attaques de “denial of service”). Les parties contractantes conviennent que la survenance de telles interruptions et/ou pannes ne donne lieu à aucune demande de recours ou de compensation de quelque nature que ce soit.</p>
<p>Les défauts sont des écarts par rapport à la description du service qui limitent l'utilisation contractuelle. Tout défaut doit être signalé en détail à Matthias Kupperschmidt. Les parties doivent coopérer à l'analyse des défauts et à la recherche de solutions. La rectification est effectuée dans le cadre de la responsabilité pour les défauts et n'entraîne pas de coûts pour le partenaire contractuel.</p>
<p>Le partenaire contractuel doit accorder à Matthias Kupperschmidt un délai adéquat pour la rectification. Si la rectification n'est pas réussie même dans un second délai de grâce adéquat (au moins, cependant, dans les 15 jours ouvrables), le partenaire contractuel est en droit de demander une réduction de paiement ou une compensation pour les dommages dus à l'inexécution s'il a annoncé cette démarche lors de la fixation du délai de grâce.</p>
<p>Les réclamations pour défauts de la part du partenaire contractuel ne peuvent plus être invoquées s'il a lui-même apporté des modifications aux services ou a fait effectuer de telles modifications par des tiers sans consentement écrit préalable, à moins que le partenaire contractuel ne prouve que les défauts ne résultent pas de ces modifications ou que ces modifications ne gênent pas l'analyse et l'élimination des défauts ou ne le font que de manière marginale.</p>
<p>Les réclamations pour défauts sont exclues en principe si le partenaire contractuel utilise les services dans des conditions d'utilisation différentes de celles spécifiées dans l'accord. Il incombe au partenaire contractuel de prouver que le défaut n'a pas été causé par une telle variation.</p>
<p>Les réclamations sont exclues pour les dommages en vertu de la loi danoise sur la vente de biens en raison de déficiences au début du contrat dont Matthias Kupperschmidt n'est pas responsable ainsi qu'en raison de déficiences uniquement négligentes.</p>
<p>Les réclamations pour défauts expirent un an après l'acceptation ; dans le cas de services non capables ou non nécessitant d'acceptation, un an après la livraison/prise en charge ou après notification de l'achèvement et/ou de la fourniture du service.</p>
<p>Matthias Kupperschmidt n'est pas responsable des fautes techniques dont les causes ne relèvent pas de la responsabilité de Matthias Kupperschmidt ni des dommages causés par des cas de force majeure. Dans des cas exceptionnels, il peut arriver que des données, services ou autres activités techniques ou réelles individuelles ne puissent pas être capturés ou correctement facturés par Matthias Kupperschmidt. Toute réclamation fondée sur cela – en particulier les réclamations pour dommages ou remplacement de valeur – contre Matthias Kupperschmidt de la part du partenaire contractuel est exclue.</p>
<p>Matthias Kupperschmidt est responsable des dommages dus à la violation d'obligations contractuelles essentielles ou en raison de l'absence de caractéristiques et de propriétés assurées. Au-delà de cela, Matthias Kupperschmidt est responsable uniquement si le dommage a été causé par une négligence grave ou une intention délibérée.</p>
<p>Matthias Kupperschmidt est responsable en cas de violation légèrement négligente d'une obligation contractuelle essentielle et, en cas de responsabilité non contractuelle, uniquement en cas de perte de vie, de blessure physique et de dommages à la santé, en cas de violation de garanties, d'obligations contractuelles essentielles ainsi que conformément à la loi sur la responsabilité du fait des produits uniquement à hauteur des dommages typiques prévisibles lors de la signature du contrat. Les dommages sont calculés sur la base du volume moyen des commandes mensuelles au cours des six derniers mois. En particulier, la responsabilité pour violation légèrement négligente des obligations contractuelles essentielles en cas de perte de profit, de dommages indirects et de dommages consécutifs est exclue en principe.</p>
<p>La responsabilité pour les dommages consécutifs, les dommages indirects et/ou la perte de profit est exclue sauf en cas de comportement intentionnel. En termes de montants, la responsabilité est limitée aux dommages normaux prévus en cas de perte au Danemark ; au maximum, cependant, à la rémunération totale à payer par le partenaire contractuel selon la commande respective. Cette limitation de responsabilité s'applique de la même manière aux dommages causés par négligence grave ou intention de la part des employés ou agents de Matthias Kupperschmidt.</p>
<p>Dans le cas où le partenaire contractuel a apporté des modifications au service contractuel ou à son environnement ou a fait effectuer de telles modifications par des tiers, toutes les réclamations du partenaire contractuel résultant des dispositions ci-dessus expirent, à moins que le défaut n'ait pas été causé, en totalité ou en partie, par les modifications effectuées par le partenaire contractuel et que la rectification ne soit pas rendue plus difficile par les modifications.</p>
<p>Ces limitations de responsabilité s'appliquent mutatis mutandis à tous les employés et agents de Matthias Kupperschmidt. Toute responsabilité de Matthias Kupperschmidt en vertu de la loi sur la responsabilité du fait des produits reste inchangée.</p>
<p>Toutes les réclamations pour dommages ou autres réclamations qui pourraient être invoquées contre Matthias Kupperschmidt en raison des propriétés et de l'état ou des défauts du service sont soumises à un délai de prescription de 6 (en toutes lettres : six) mois après la fin du contrat respectif.</p>
<p>Le partenaire contractuel est responsable envers Matthias Kupperschmidt du contenu, des documents, des liens et autres matériaux (appelés ci-après “documents”) qu'il fournit et met à la disposition de Matthias Kupperschmidt pour la mise en œuvre du projet de collaboration.</p>
<p>Si nécessaire, le partenaire contractuel doit vérifier à ses propres frais si les services rendus par Matthias Kupperschmidt sont conformes aux dispositions légales pertinentes. Matthias Kupperschmidt n'engagera une telle vérification légale externe que sur demande écrite expresse du partenaire contractuel ; les coûts associés seront à la charge du partenaire contractuel.</p>
<p>Dans le cas où des tiers feraient valoir des réclamations contre Matthias Kupperschmidt en raison d'une violation des droits d'auteur et/ou d'une violation des dispositions légales pertinentes survenue dans le cadre des obligations du partenaire contractuel, le partenaire contractuel doit faire tout son possible pour défendre Matthias Kupperschmidt contre les réclamations ou droits invoqués et pour éliminer la violation alléguée. Le partenaire contractuel est également responsable envers Matthias Kupperschmidt de tout dommage causé par des tiers qui a été encouru en conséquence de l'exécution partielle, fautive, retardée, tardive ou non exécution de la commande. Il en va de même pour tout agent du partenaire contractuel. Le partenaire contractuel doit supporter la charge de la preuve de l'exécution correcte de la commande.</p>
<p>Le partenaire contractuel dégage Matthias Kupperschmidt de toutes les réclamations pour dommages, réclamations de responsabilité et coûts que Matthias Kupperschmidt encourt en raison du fait qu'une réclamation est formulée contre lui selon laquelle les documents du partenaire contractuel enfreignent le droit de la concurrence, portent atteinte aux droits de propriété industrielle de tiers ou à d'autres lois et réglementations. En particulier, le partenaire contractuel doit dégager Matthias Kupperschmidt, à première demande, immédiatement de toute réclamation de tiers invoquée contre Matthias Kupperschmidt en raison du contenu utilisé ou d'une offre liée par hyperlien, notamment en vertu de la loi danoise sur la protection des données (DDPA) et du Règlement Général sur la Protection des Données (RGPD) de l'UE et doit rembourser, sur demande, tous les coûts encourus par Matthias Kupperschmidt en raison d'une action en justice appropriée.</p>

<p>Section 10 Admissibilité des sites Web</p>
<p>morefire ne vérifie pas si le contenu des sites Web du client porte atteinte aux droits de tiers ou est conforme aux directives des différents opérateurs de moteurs de recherche. Le client est seul responsable de toute sanction par des tiers.</p>
<p>Le client est également responsable de la légalité du contenu de ses sites Web ainsi que des informations fournies par lui, y compris les termes de recherche ou mots-clés ; il en va de même pour la protection des droits de tiers, notamment en termes de droit d'auteur, de droit de la concurrence et de droit pénal.</p>

<p>Section 11 Contrat de service</p>
<p>Les employés déployés dans le cadre de contrats de service ne sont pas autorisés à être débauchés par le client dans un délai de 24 mois après l'attribution du contrat. Si une telle sollicitation a lieu dans ce délai, le contractant a droit à un paiement unique de 75 000 €.</p>

<p>Section 12 Protection des données</p>
<p>Sans déclaration de consentement supplémentaire, les données personnelles – sauf indication contraire – sont collectées, traitées et utilisées uniquement dans le but de fournir les services, d'utiliser les services et, le cas échéant, de facturer.</p>
<p>Le partenaire contractuel est tenu de se conformer à toutes les dispositions applicables en matière de protection des données, en particulier les dispositions de la loi danoise sur la protection des données (DDPA) et du Règlement Général sur la Protection des Données (RGPD) de l'UE.</p>
<p>Le partenaire contractuel garantit d'utiliser les données qu'il pourrait avoir collectées exclusivement pour le but déclaré ainsi que pour des fins d'études de marché et de publicité au sein de l'entreprise, dans la mesure où cela est nécessaire pour l'exécution des transactions conclues et le maintien de la relation utilisateur en résultant et si cela est permis par la loi et souhaité par l'utilisateur. La revente des données à des tiers par le partenaire contractuel est exclue, et des poursuites seront engagées.</p>

<p>Section 13 Confidentialité et secret</p>
<p>Le partenaire contractuel est tenu d'utiliser les documents, connaissances et expériences obtenus de Matthias Kupperschmidt uniquement aux fins de ce contrat. En outre, les parties contractantes conviennent de la confidentialité vis-à-vis des tiers concernant le contenu des accords conclus ainsi que toute connaissance acquise au cours de leur mise en œuvre. Le partenaire contractuel est tenu de consulter Matthias Kupperschmidt en cas de doute quant à savoir si une information dans un cas individuel spécifique doit être traitée de manière confidentielle.</p>
<p>En cas de sous-traitance éventuelle, le partenaire contractuel doit engager le sous-traitant respectif à respecter les obligations au sens de cette disposition.</p>
<p>Le partenaire contractuel doit veiller au respect de cette obligation de confidentialité par ses employés et tout tiers impliqué dans la mise en œuvre du contrat qui ont accès aux documents et objets spécifiés dans le premier paragraphe.</p>

<p>Section 14 Modifications</p>
<p>Matthias Kupperschmidt fournira ses services conformément aux descriptions de produits respectives. Matthias Kupperschmidt se réserve le droit de modifier, d'élargir ou d'adapter les services, en tout ou en partie, ou de modifier, d'élargir ou d'adapter le type et le contenu des produits et services individuels offerts dans le cadre de la prestation des services à tout moment et de manière répétée pour améliorer l'offre, en particulier ses fonctions, son design et son développement technique. Le partenaire contractuel sera informé des modifications majeures des produits par e-mail en temps opportun. La nouvelle version des descriptions de produits sera jointe. Si le partenaire contractuel n'est pas d'accord avec la modification, il est en droit de résilier le contrat avec Matthias Kupperschmidt avec effet immédiat. Si le partenaire contractuel ne s'oppose pas à la modification dans un délai de deux semaines, les modifications notifiées sont réputées être les nouvelles conditions.</p>
<p>En outre, Matthias Kupperschmidt est en droit de supprimer des fonctions individuelles offertes dans le cadre des services – en particulier en cas d'exigences légales modifiées, de pertes durables, de difficultés techniques ou en cas d'abus par des clients, des partenaires contractuels ou des tiers.</p>
<p>Matthias Kupperschmidt se réserve également expressément le droit de modifier les prix, dont Matthias Kupperschmidt informera le partenaire contractuel dans un délai raisonnable.</p>
<p>Matthias Kupperschmidt se réserve également le droit de modifier ou de compléter ces CGV, en particulier en cas d'introduction de nouveaux services ou de modifications requises par des dispositions légales. Matthias Kupperschmidt notifiera les modifications ou ajouts aux CGV au partenaire contractuel. Si le partenaire contractuel n'est pas d'accord avec les modifications, il peut s'y opposer dans un délai de deux semaines après leur communication par écrit.</p>
<p>Dans le cas où le partenaire contractuel s'oppose aux modifications des services, des CGV ou des conditions particulières pour les produits individuels, Matthias Kupperschmidt a le droit de résilier sans préavis les contrats conclus en vertu de ces CGV. Si le partenaire contractuel ne s'oppose pas, son consentement est réputé donné à l'expiration des délais mentionnés ci-dessus. Matthias Kupperschmidt indiquera la durée de la période et la signification de son expiration dans la notification de la modification des CGV.</p>

<p>Section 15 Dispositions finales</p>
<p>L'envoi de notifications à Matthias Kupperschmidt par e-mail satisfait à l'exigence de forme écrite uniquement si cela est expressément prévu dans ces conditions d'utilisation et si Matthias Kupperschmidt y a expressément consenti au préalable.</p>
<p>La cession des droits et obligations contractuels par le partenaire contractuel à un tiers n'est permise qu'avec le consentement écrit préalable de Matthias Kupperschmidt.</p>
<p>Le partenaire contractuel a un droit de rétention uniquement en termes de créances incontestées ou légalement établies.</p>
<p>Il n'existe aucun accord collatéral. Tous les accords entre les parties doivent être établis par écrit. Cela s'applique également à l'annulation de l'exigence de forme écrite.</p>
<p>Le partenaire contractuel informera Matthias Kupperschmidt en temps opportun des procédures d'insolvabilité en cours afin d'assurer l'exécution des services et obligations contractuels ou de permettre une résiliation consensuelle du contrat.</p>
<p>Si certaines dispositions des Conditions Générales sont inefficaces en tout ou en partie ou contiennent une lacune, l'efficacité des autres dispositions ou parties de ces dispositions n'en est pas affectée. Dans ce cas, les parties sont tenues de participer à l'élaboration de nouvelles dispositions, grâce auxquelles un résultat commercial est obtenu avec un effet juridique qui se rapproche le plus possible de la disposition inefficace ou incomplète, tout en maintenant les intérêts mutuels.</p>
<p>Le lieu d'exécution pour les deux parties est le lieu d'affaires de Matthias Kupperschmidt.</p>
<p>Le droit de l'État danois s'applique exclusivement à l'exclusion de la Convention des Nations Unies sur les contrats de vente internationale de marchandises (CISG) et des renvois au droit étranger.</p>
<p>Si le client est un commerçant, une personne morale de droit public ou un fonds spécial de droit public ou s'il n'a pas de siège social au Danemark, le lieu de juridiction exclusif est Copenhague, Danemark. Matthias Kupperschmidt est également en droit d'intenter une action en justice au lieu d'affaires du partenaire contractuel.</p>

<p>Section 16 Règlement des litiges</p>
<p>La Commission européenne a mis en place une plateforme pour le règlement en ligne des litiges. Elle est accessible à l'adresse : http://ec.europa.eu/consumers/odr/.</p>
<p>Les consommateurs peuvent utiliser la plateforme pour le règlement des litiges. Matthias Kupperschmidt n'est ni disposé ni obligé de participer à des procédures de règlement des litiges devant un organisme de médiation des consommateurs, sauf s'il existe une obligation légale de participation.</p>
<p>Copenhague, le 17 juin 2024</p>


</MainContent>
</React.Fragment>

</Layout>
);
};
export default LegalNotice;


